import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { ProjectYearSelection } from 'src/scenes/LoadProject/components';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import Loading from 'src/components/Loading';
import landscapeSchema, { arraySchemaIds } from 'src/scenes/Landscape/landscapeValidationSchema';
import { Map, Page, Section, Project, LeaveConfirm, AlertsList, DialogWrapper, ObservationsSection,
  RemoveGeomsDialog } from 'src/components';
import { SelectLandscapeSection, ObservationPointsForm, LandscapeUnitForm, ViewshedForm } from 'src/scenes/Landscape/components';
import { projectApi, optionsApi, landscapeApi } from 'src/services';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { filterGeomTypeFromGeoJson, isInvalidGeometry, geometryToFeature, makeErrors,
  separateCollectionsFromFeatureCollection } from 'src/utils';
import { useMustLeave, useMustReload, useSetHighlightedFeat } from 'src/hooks';


const typeKey = 'landscape';

const DialogContents = ({ type, actions }) => {
  switch (type) {
    case 'remove-geoms': {
      return <RemoveGeomsDialog
        confirmFn={actions.deleteGeoJson}
        closeDialog={actions.closeDialog}
        type="soil"
      />;
    }
    case 'upload-kml-landscape-section': {
      return <SelectLandscapeSection
        confirmFn={actions.confirmDialog}
        closeDialog={actions.closeDialog}
        type="soil"
      />;
    }
    default:
      break;
  }
};

DialogContents.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  actions: PropTypes.object,
};

const accordionTransitionPropObject = { unmountOnExit: true };

const useStyles = makeStyles(theme => ({
  comments: {
    width: '100%',
  },
  hr: {
    marginLeft: 0,
    width: '25%',
  },
  success: {
    color: 'green',
  },
  accordionSummary: {
    backgroundColor: '#eee',
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
}));

const LandscapeForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { seaProjectId } = match.params;

  const history = useHistory();
  const pushProjectToHistory = seaProject => history.push({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const replaceProjectToHistory = seaProject => history.replace({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const { state: locationState } = useLocation();
  const [ seaProject, setSeaProject ] = useState(locationState?.seaProject);
  const [ kmlAlerts, setKmlAlerts ] = useState([]);
  const [ dialog, setDialog ] = useState({ isOpen: false, type: null, data: null, actions: null });
  const [ formOptions, setFormOptions ] = useState(null);
  const [ sendingData, setSendingData ] = useState(false);

  const [ form, setForm ] = useState({
    geoJson: null,
    badData: false,
    observationPoints: [],
    viewsheds: [],
    landscapeUnits: [],
    comments: '',
  });

  const [ kmlFileNamesBySection, setKmlFileNamesBySection ] = useState({
    landscapeUnits: [],
    viewsheds: [],
    observationPoints: [],
  });

  const makeEmptyElement = {
    landscapeUnits: ({ featId, name = '', dataOnWeb = false }) => ({
      name,
      featId,
      dataOnWeb,
      finished: false,
      biophysicalAttributes: null,
      aestheticAttributes: null,
      structuralAttributes: null,
      finalVisualQuality: null,
      particularCharacteristics: '',
      areaHa: null,
    }),
    viewsheds: ({ featId, name = '', dataOnWeb = false }) => ({
      name,
      featId,
      dataOnWeb,
      finished: false,
      observationPoints: [],
      areaHa: null,
    }),
    observationPoints: ({ featId, name = '', dataOnWeb = false }) => ({
      name,
      featId,
      dataOnWeb,
      finished: false,
      description: '',
      locality: '',
    }),
  };

  const layersRef = useRef({});

  const featurePopUp = feature => {
    const fProps = feature.properties;
    return `<div>${feature.properties.sectionLabel} #${fProps.elementNumber}</div>`;
  };

  const onEachFeature = (feature, layer) => {
    layer.bindPopup(featurePopUp(feature));
    layersRef.current[feature.properties.id] = layer;
  };

  const [ loadingProject, setLoadingProject ] = useState(!seaProject);
  const [ loadedFormDataAndOptions, setLoadedFormDataAndOptions ] = useState(false);
  const [ projectYear, setProjectYear ] = useState(seaProject?.year ?? null);
  const [ randomProjectNotFound, setRandomProjectNotFound ] = useState(false);
  const [ errors, setErrors ] = useState({});
  const dbDataRef = useRef(null);

  const { mustLeave, leavePage } = useMustLeave({ history });
  const { mustReload, reloadPage } = useMustReload();

  const lastSavedCommentsRef = useRef('');
  // cosas para prevenir que maten la página por accidente cuando tienen datos no guardados:
  const checkLeaveConfirmNeeded = () => !mustLeave &&
    form.comments !== lastSavedCommentsRef.current ||
    form.observationPoints.some(op => !op.dataOnWeb) || form.viewsheds.some(v => !v.dataOnWeb) ||
    form.landscapeUnits.some(lu => !lu.dataOnWeb);

  const closeDialog = useCallback(() => setDialog({ isOpen: false, type: null, data: null, actions: null }), []);

  const onSelectedSection = useCallback((filteredGeoJson, kmlFileName) => selected => {
    const { value: sectionName, label: sectionLabel } = selected;
    if (kmlFileNamesBySection[sectionName].includes(kmlFileName)) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `Ya ha sido cargado un archivo con el nombre ${kmlFileName} en esa sección.` },
      ]);
      return;
    }

    const pointElements = [];
    const polyElements = [];

    for (let i = 0; i < filteredGeoJson.features.length; i++) {
      const feature = filteredGeoJson.features[i];
      const { properties } = feature;

      let name;

      Object.keys(properties).forEach(key => {
        const normalizedKey = key.toLowerCase().trim();
        if (normalizedKey === 'nombre' || normalizedKey === 'name') {
          name = properties[key]?.trim();
        }
      });

      const nameStr = name ? `Nombre: ${name}` : '';
      if (isInvalidGeometry(feature, { allowMulti: true })) {
        setKmlAlerts([ { type: 'error', message: `Se encontró una geometría con coordenadas inválidas ${nameStr}` } ]);
        return;
      }
      const featId = i + (form.geoJson?.features?.length ?? 0);
      const featProps = { name, id: featId, sectionLabel };
      const featType = feature.geometry?.geometries?.[0].type ?? feature.geometry?.type;
      if (featType.includes('Poly')) {
        polyElements.push(makeEmptyElement[sectionName]({ featId, name }));
        featProps.elementNumber = polyElements.length;
        featProps.sectionLabel = sectionLabel;
      } else if (featType.includes('Point')) {
        pointElements.push(makeEmptyElement['observationPoints']({ featId, name }));
        featProps.elementNumber = pointElements.length;
        featProps.sectionLabel = 'Punto de observación';
      }
      filteredGeoJson.features[i] = { ...feature, properties: featProps };
    }
    setForm(pf => ({
      ...pf,
      geoJson: {
        type: 'FeatureCollection',
        features: [ ...(pf.geoJson?.features ?? []), ...filteredGeoJson.features ],
      },
      ...(sectionName !== 'observationPoints' ? { [sectionName]: [ ...(pf[sectionName] ?? []), ...polyElements ] } : {}),
      observationPoints: [ ...pf.observationPoints, ...pointElements ],
    }));

    setKmlFileNamesBySection(pf => ({
      ...pf,
      [sectionName]: [ ...pf[sectionName], kmlFileName ],
    }));

    return;
  // eslint-disable-next-line
  }, [form, kmlFileNamesBySection]);

  const openSelectLandscapeSection = useCallback((geoJson, kmlFileName) => {
    setDialog({
      isOpen: true, type: 'upload-kml-landscape-section',
      actions: { closeDialog, confirmDialog: onSelectedSection(geoJson, kmlFileName) },
    });
  }, [ closeDialog, onSelectedSection ]);

  useEffect(() => {
    if (seaProjectId) {
      const fetchFormDataAndOptions = async () => {
        try {
          const [ prevData, landscapeOptions ] = await Promise.all([
            landscapeApi.getData(seaProjectId),
            optionsApi.getLandscapeOptions(),
          ]);
          if (prevData) {

            const features = [ ...Array(prevData.landscapeUnits.length + prevData.viewsheds.length +
              prevData.observationPoints.length) ];

            const tempData = {
              landscapeUnits: [],
              viewsheds: [],
              observationPoints: [],
            };
            arraySchemaIds.forEach(formName => {
              for (let ind = 0; ind < prevData[formName].length; ind++) {
                const { geometry, ...restData } = prevData[formName][ind];
                const { name, featId, sectionLabel } = restData;
                tempData[formName].push({ ...restData, dataOnWeb: true });
                features[featId] = geometryToFeature({
                  geometry, id: featId, properties: { name, featId, elementNumber: ind + 1, sectionLabel },
                });
              }
            });

            setForm({
              geoJson: {
                type: 'FeatureCollection',
                features,
              },
              ...tempData,
              comments: prevData.comments,
              badData: prevData.badData,
            });
            lastSavedCommentsRef.current = prevData.comments;
          }
          dbDataRef.current = prevData;
          setFormOptions({
            landscapeOptions: {
              options: landscapeOptions,
              labels: landscapeOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
            },
          });
          setLoadedFormDataAndOptions(true);
        } catch (e) {
          console.log(e);
          toast.error(
            e.serverMessage ?? e.serviceMessage ?? 'Hubo un error al cargas los datos del formulario, por favor intenta más tarde',
          );
        }
      };
      fetchFormDataAndOptions();
    } else {
      getPrioritizedProject();
    }
  // eslint-disable-next-line
  }, [ seaProjectId ]);

  // TODO: estado de error y mensaje de error para esto
  useEffect(() => {
    if (seaProjectId && !seaProject) {
      const fetchData = async () => {
        setLoadingProject(true);
        const { seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setSeaProject(seaProject);
        setLoadingProject(false);
      };
      fetchData();
    }
  }, [ seaProjectId, seaProject ]);

  const getPrioritizedProject = async () => {
    try {
      const result = (await projectApi.getPriorityProject({ type: typeKey }))?.data;
      if (result) {
        const { seaProject } = result;
        setSeaProject(result.seaProject);
        if (!seaProject) {
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
          replaceProjectToHistory(seaProject);
        }
      } else {
        setLoadingProject(false);
      }
    } catch (err) {
      setLoadingProject(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
    }
  };

  const getRandomizedProject = async year => {
    setLoadingProject(true);
    setRandomProjectNotFound(false);
    try {
      const result = (await projectApi.getRandomProject({ year, type: typeKey }))?.data;
      const { seaProject } = result || {};
      if (!seaProject) {
        setRandomProjectNotFound(true);
        setLoadingProject(false);
      } else {
        setSeaProject(seaProject);
        setLoadingProject(false);
        pushProjectToHistory(seaProject);
      }
    } catch (err) {
      toast.error(err.serverMessage ?? err.serviceMessage ?? 'Hubo un error al pedir el proyecto. Por favor intenta más tarde');
      console.error(err);
    }
  };

  const onChangeProjectYear = ({ projectYear }) => {
    setProjectYear(projectYear);
    getRandomizedProject(projectYear);
  };

  const handleGeoJson = ({ geoJson, kmlFileName }) => {

    if (!geoJson.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece contener geometrías o no se pudieron analizar sus geometrías` },
      ]);
    }
    const validTypes = [ 'Polygon', 'Point', 'MultiPolygon', 'MultiPoint' ];
    const { geoJson: filteredGeoJson, changed, changedObj } = filterGeomTypeFromGeoJson({ geoJson, validTypes });
    if (!filteredGeoJson?.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece tener ni polígonos ni puntos` },
      ]);
      return;
    } else if (changed) {
      // Si se filtró algo del ḰML, advertir y continuar.
      setKmlAlerts(ps => {
        let typeFilterWarningActive = false;
        let nullFilterWarningActive = false;
        const finalList = ps.filter(w => {
          typeFilterWarningActive |= w.key === 'type-filter';
          nullFilterWarningActive |= w.key === 'null-filter';
          return w.type === 'warning';
        });

        const newWarnings = [];
        for (const changeKey in changedObj) {
          if (changeKey === 'null' && !nullFilterWarningActive) {
            newWarnings.push({
              type: 'warning',
              message: `Se encontraron y filtraron geometrías vacías. Considera revisar el KML si esto no te parece correcto`,
              key: 'null-filter',
            });
          } else if (changeKey !== 'null' && !typeFilterWarningActive) {
            newWarnings.push({
              type: 'warning', message: `Se han filtrado geometrías que no eran ni polígonos ni puntos`, key: 'type-filter',
            });
          }
        }
        return [ ...finalList, ...newWarnings ];
      });
    } else {
      setKmlAlerts(pa => {
        // Limpiamos errores de kmls pasados que no puedieron subirse, pero dejamos las warnings (que son de kmls que sí se subieron)
        const warnings = pa.filter(alert => alert.type === 'warning');
        return warnings;
      });
    }

    let docHasPoly = false;
    const processedGeoJson = separateCollectionsFromFeatureCollection({ featureCollection: filteredGeoJson });
    processedGeoJson.features.forEach(feature => {
      docHasPoly = docHasPoly || (feature.geometry?.geometries?.[0].type ?? feature.geometry?.type.includes('Poly'));
    });
    if (docHasPoly) {
      openSelectLandscapeSection(processedGeoJson, kmlFileName);
    } else {
      onSelectedSection(processedGeoJson, kmlFileName)({ value: 'observationPoints', label: 'Punto de observación' });
    }
  };

  const onSubmitGenerator = (stayHere = false) => async () => {
    if (sendingData || mustLeave) {
      return;
    }
    try {
      setErrors({});
      form.seaId = seaProjectId;
      setSendingData(true);
      await landscapeSchema.validate(form, { abortEarly: false });
      toast.info('Guardando la información');
      const { message } = await landscapeApi.saveForm(form);
      setSendingData(false);
      toast.dismiss();
      toast.success(message);
      if (!stayHere) {
        leavePage();
      } else {
        reloadPage();
      }
    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      if (e.name === 'ValidationError') {
        toast.error(<div>Hay problemas con el formulario.<br/>Por favor revisar</div>,
          { autoClose: 10000, allowHtml: true },
        );
        const formErrors = makeErrors(e, arraySchemaIds);
        console.error(`Problem submit form: ${e}`);
        console.error({ formErrors });
        setErrors(formErrors);
      } else {
        console.error(e);
        toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      }
    }
  };

  const onSubmit = onSubmitGenerator(false);
  const onSubmitReloadPage = onSubmitGenerator(true);

  const { setHighlightedFeat } = useSetHighlightedFeat({ layersRef });

  const deleteGeoJson = useCallback(() => {
    setForm(pf => ({
      ...pf, geoJson: null, landscapeUnits: [], viewsheds: [], observationPoints: [],
      ...(dbDataRef.current && { deletePreviousData: true }),
    }));
    setKmlAlerts([]);
    setHighlightedFeat({ featId: null });
    setKmlFileNamesBySection({
      landscapeUnits: [],
      viewsheds: [],
      observationPoints: [],
    });
  // eslint-disable-next-line
  }, [ setHighlightedFeat ]);

  const openDeleteGeoJsonDialog = useCallback(() =>
    setDialog({ isOpen: true, type: 'remove-geoms', actions: { closeDialog, deleteGeoJson } })
  , [ closeDialog, deleteGeoJson ]);

  const formStatus = useMemo(() => {
    if (!loadedFormDataAndOptions) {
      return null;
    }
    const dbData = dbDataRef.current;
    if (dbData) {
      if (!form.geoJson && !form.badData) {
        return { message: 'Pendiente', status: 'pending', updatedAt: dbData.updatedAt };
      } else if (form.geoJson && (form.landscapeUnits.some(lu => !lu.finished) || form.viewsheds.some(v => !v.finished)
       || form.observationPoints.some(op => !op.finished)
      )) {
        return {
          message: 'En progreso (faltan datos de formularios por completar)',
          status: 'inProgress',
          updatedAt: dbData.updatedAt,
        };
      } else {
        return { message: 'Enviado', status: 'success', updatedAt: dbData.updatedAt };
      }
    } else {
      return { message: 'Pendiente', status: 'pending' };
    }
  // eslint-disable-next-line -- Todas las dependencias están bien en el punto en que este valor cambia
  }, [ loadedFormDataAndOptions ])

  return loadingProject ? <Loading/> : <>
    {!seaProject && <>
      <ProjectYearSelection formType={typeKey} projectYear={projectYear} updateState={onChangeProjectYear} />
      { randomProjectNotFound && <Typography variant="body1">No se encontró proyecto para el año seleccionado</Typography> }
    </>}
    { seaProject && (!loadedFormDataAndOptions ? <Loading/> : <>
      <Section title="Proyecto">
        { !mustLeave && !mustReload && <LeaveConfirm checkConfirmNeeded={checkLeaveConfirmNeeded}/> }
        <Box>
          <Project project={seaProject} formType={typeKey} formStatus={formStatus}></Project>
        </Box>
      </Section>
      <Page title={`Paisaje - ${seaProject.nombre}`}>
        <Box px={4}>
          <Map nameId={'landscapeMap'} geoJson={form.geoJson} importKml={true} deleteGeoJson={openDeleteGeoJsonDialog}
            mapHeight={'24rem'} allowMulti handleGeoJson={handleGeoJson} onEachFeature={onEachFeature}
          />
        </Box>
        <Box mx={4} my={2}>
          { Boolean(kmlAlerts.length) &&
            <AlertsList alerts={kmlAlerts} />
          }
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={form.badData} onChange={event => setForm(pf => ({ ...pf, badData: event.target.checked }))}
              />
            }
            labelPlacement="start"
            label="¿Datos erróneos o no disponibles?"
          />
        </Box>
        <Box display='flex' mt={ 4 } mb={ 1 } justifyContent='space-between'>
          <Typography variant="h4" gutterBottom>
            Valor paisajistico
          </Typography>
        </Box>

        { Boolean(form.landscapeUnits.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>Unidades de paisaje <small>({form.landscapeUnits.length})</small></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.landscapeUnits.map((lu, index) =>
                  <LandscapeUnitForm key={`op-${index}`} index={index} landscapeUnit={lu}
                    setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                    landscapeOptions={formOptions.landscapeOptions}
                    errors={errors.landscapeUnits?.[index]}/>,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        { Boolean(form.observationPoints.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>Puntos de observación <small>({form.observationPoints.length})</small></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.observationPoints.map((op, index) =>
                  <ObservationPointsForm key={`op-${index}`} index={index} observationPoint={op}
                    setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                    viewsheds={form.viewsheds}
                    errors={errors.observationPoints?.[index]}/>,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        { Boolean(form.viewsheds.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>Cuencas visuales <small>({form.viewsheds.length})</small></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.viewsheds.map((v, index) =>
                  <ViewshedForm key={`v-${index}`} index={index} viewshed={v}
                    setForm={setForm} setHighlightedFeat={setHighlightedFeat}
                    errors={errors.viewsheds?.[index]}
                    observationPoints={form.observationPoints}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        <ObservationsSection onChange={e => setForm(pf => ({ ...pf, comments: e.target.value }))}
          value={form.comments} className={classes.comments}
        />
        { errors.geoJson &&
          <Box>
            <Alert severity="error">{errors.geoJson.errorMessage}</Alert>
          </Box>
        }
        <Box display="flex" flex={2}>
          <Box mx={1.5}>
            <Button className={classes.submitButton} type="button" variant="contained" color="secondary"
              disabled={sendingData || mustLeave} onClick={onSubmitReloadPage} >
              Guardar progreso
            </Button>
          </Box>
          <Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary"
              disabled={sendingData || mustLeave } onClick={onSubmit} >
              Enviar
            </Button>
          </Box>
        </Box>
        <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
          { dialog.isOpen && <DialogContents type={dialog.type} actions={dialog.actions} data={dialog.data} /> }
        </DialogWrapper>
      </Page>
    </>)}
  </>;
};


export { LandscapeForm };
