import yup from 'src/scenes/yup';


// TODO: confirmar obligatoriedad de todas las cosas
const campaignSchema = {
  name: yup.string().nullable(),
  absoluteAbundance: yup.number().min(0).nullable(),
  durationDays: yup.number().min(0).nullable(),
};

const stationSchemaOnlyRecords = {
  records: yup.array().of(
    yup.object({
      scientificName: yup.string().nullable(),
    })).test('unique-name', 'Formulario contiene nombre de especies duplicadas por estación',
    records => {
      const nameSet = new Set();
      for (const record of records) {
        const scientificName = record.scientificName;
        if (scientificName) {
          if (nameSet.has(scientificName)) {
            return false;
          }
          nameSet.add(scientificName);
        }
      }
      return true;
    }),
};

const stationSchema = {
  name: yup.string().nullable(),
  campaignIds: yup.array().of(yup.number()).when('finished', {
    then: schema => schema.nullable(),
    otherwise: schema => schema.min(1, 'Debe haber al menos una campaña asignada cuando la estación está finalizada'),
  }),
  records: stationSchemaOnlyRecords['records'],
};

const faunaSchema = yup.object({
  geoJson: yup.object()
    .when([ 'badData', 'campaigns' ], {
      is: (badData, campaign) => badData === true || (Array.isArray(campaign) && campaign.length > 0),
      then: schema => schema.nullable(),
      otherwise: schema =>
        schema.required('Si no puedes cargar o registrar información, marca la casilla de datos erróneos o no disponibles'),
    }),
  campaigns: yup.array().of(yup.lazy(c => !c.finished ? yup.object() : yup.object().shape(campaignSchema))),
  terrestrial: yup.array().of(yup.lazy(s => !s.finished ?
    yup.object().shape(stationSchemaOnlyRecords) :
    yup.object().shape(stationSchema))),
  aquatic: yup.array().of(yup.lazy(s => !s.finished ? yup.object().shape(stationSchemaOnlyRecords) : yup.object().shape(stationSchema))),
  // TODO: decidir si validamos badData y comentarios? (por ej. que si badData entonces los comentarios no estén vacíos...)
  badData: yup.boolean(),
  comments: yup.string(),
});

export const arraySchemaIds = [ 'campaigns', 'terrestrial', 'aquatic' ];


export default faunaSchema;