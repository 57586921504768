import yup from 'src/scenes/yup';


const vibrationValidation = value => {
  const strValue = String(value);
  if (strValue) {
    const splited = strValue.split(';');
    if (splited.length === 1) {
      return value <= 1000000;
    } else {
      const parts = splited.map(v => v.trim());
      return (
        parts.length === 3 &&
        parts.every(part => part && Number(part) <= 1000000)
      );
    }
  }
  return false;
};

// TODO: confirmar obligatoriedad de todas las cosas
const fieldMeasurementSchema = {
  name: yup.string().nullable(),
  dayNoiseLevel: yup.number().max(1000000).nullable(),
  dayNoiseDescriptor: yup.string().nullable(),
  unit: yup.string().nullable(),
  dayMeasurementDate: yup.string().nullable(),
  dayMeasurementHour: yup.string().nullable(),
  nightNoiseLevel: yup.number().max(1000000).nullable(),
  nightNoiseDescriptor: yup.string().nullable(),
  nightMeasurementDate: yup.string().nullable(),
  nightMeasurementHour: yup.string().nullable(),
  methodDescription: yup.string().nullable(),
  distToProject: yup.number().max(100000000).nullable(),
};

const continuousMeasurementSchema = {
  name: yup.string().nullable(),
  startDate: yup.string().nullable(),
  endDate: yup.string().nullable(),
  totalDays: yup.number().max(10000).nullable(),
  dayMeasurement68: yup.number().max(100000).nullable(),
  dayMeasurement810: yup.number().max(100000).nullable(),
  dayMeasurement1012: yup.number().max(100000).nullable(),
  nightMeasurement68: yup.number().max(100000).nullable(),
  nightMeasurement810: yup.number().max(100000).nullable(),
  nightMeasurement1012: yup.number().max(100000).nullable(),
};

const vibrationLevelSchema = {
  name: yup.string().nullable(),
  dayVibrationMeasurement: yup.
    string()
    .test('format-check', 'Formato inválido', vibrationValidation).nullable(),
  nightVibrationMeasurement: yup.
    string()
    .test('format-check', 'Formato inválido', vibrationValidation).nullable(),
  vibrationUnit: yup.string().nullable(),
  dayMeasurementDate: yup.string().nullable(),
  dayMeasurementHour: yup.string().nullable(),
  nightMeasurementDate: yup.string().nullable(),
  nightMeasurementHour: yup.string().nullable(),
  methodDescription: yup.string().nullable(),
  distToProject: yup.number().max(100000000).nullable(),
};

const receptorSchema = {
  name: yup.string().nullable(),
  description: yup.string().nullable(),
  distToProject: yup.number().max(100000000).nullable(),
  environmentalComponents: yup.array().of(yup.string()).nullable(),
};

const noiseSchema = yup.object({
  geoJson: yup.object()
    .when('badData', { is: true,
      then: schema => schema.nullable(),
      otherwise: schema => schema.required('Si no puedes dar geometrías, marca la casilla de datos erróneos o no disponibles'),
    }),
  fieldMeasurements: yup.array().of(yup.lazy(fm => !fm.finished ? yup.object() : yup.object().shape(fieldMeasurementSchema))),
  continuousMeasurements: yup.array().of(yup.lazy(cm => !cm.finished ? yup.object() : yup.object().shape(continuousMeasurementSchema))),
  vibrationLevels: yup.array().of(yup.lazy(vl => !vl.finished ? yup.object() : yup.object().shape(vibrationLevelSchema))),
  receptors: yup.array().of(yup.lazy(r => !r.finished ? yup.object() : yup.object().shape(receptorSchema))),
  // TODO: decidir si validamos badData y comentarios? (por ej. que si badData entonces los comentarios no estén vacíos...)
  badData: yup.boolean(),
  comments: yup.string(),
});

export const arraySchemaIds = [ 'continuousMeasurements', 'fieldMeasurements', 'receptors', 'vibrationLevels' ];


export default noiseSchema;