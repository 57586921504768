import React, { memo, useCallback } from 'react';
import { Grid, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel, DangerButton } from 'src/components';
import { parseNumber, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const getOptionSelected = (option, value) => option.value === value;

const speciesStatusOptions = [
  { label: 'Peligro Critico (CR)', value: 'cr' },
  { label: 'En peligo (EN)', value: 'en' },
  { label: 'Vulnerable (VU)', value: 'vu' },
];

const speciesStatuslabels = {
  cr: 'Peligro Critico (CR)',
  en: 'En peligo (EN)',
  vu: 'Vulnerable (VU)',
};

const stopEventPropagation = event => event.stopPropagation();

const FaunaRecordForm = memo(({ record, index, onUpdate, originOptions, recordType, deleteRecord, containsScientificName }) => {
  const { finished, dataOnWeb } = record;

  const onUpdateRecord = useCallback(data => onUpdate(index)(data), [ onUpdate, index ]);

  const scientificNameHasError = containsScientificName(record.scientificName);

  const clickDeleteRecord = useCallback(() => deleteRecord(record, index)
    , [ deleteRecord, record, index ]);

  const onChange = useCallback(e => {
    const field = e.target.name;
    const data = e.target.value;
    onUpdateRecord({ field, data });
  }, [ onUpdateRecord ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const getSpeciesStatuslabels = value => speciesStatuslabels[value.value ?? value];

  const getOriginLabels = value => originOptions.labels[value.value ?? value];

  const onBlurNumber = useCallback(e => {
    onChange({ target: { name: e.target.name, value: parseNumber(e.target.value, 0) ?? null } });
  }, [ onChange ]);

  const onChangeNumber = useCallback(e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals: 0, wholePart: 6 })) {
      return;
    }
    onChange(e);
  }, [ onChange ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { scientificNameHasError &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema con datos del registro!
            </Box>
          }
          <Typography>
            Especie: {record.scientificName || record.commonName || 'Sin definir'}
          </Typography>
        </Box>
        <Box>
          <Box display="inline" onClick={stopEventPropagation}>
            <DangerButton variant="contained" onClick={clickDeleteRecord}>
              Borrar
            </DangerButton>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${index}-name`}>Nombre cientifico</TypographicInputLabel>
          <TextField value={record.scientificName || ''} name="scientificName" id={`${index}-scientific-name`} variant="outlined"
            size="small" fullWidth autoComplete="off" onChange={onChange}
            error={scientificNameHasError}
            helperText={scientificNameHasError ? 'Nombre de especie ya registrada' : ''}
            placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${index}-common-name`}>Nombre común</TypographicInputLabel>
          <TextField value={record.commonName || ''} name="commonName" id={`${index}-common-name`} variant="outlined"
            size="small" fullWidth autoComplete="off" onChange={onChange}
            placeholder={'No informado'}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${index}-origin`}>Origen</TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Autocomplete
              value={record.origin}
              name={'origin'}
              options={originOptions.options}
              getOptionLabel={getOriginLabels}
              getOptionSelected={getOptionSelected}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${index}-origin`}
                  variant="outlined"
                  placeholder={record.origin ? '' : 'Ej: Nativa'}
                />
              )}
              onChange={(event, data) => {
                onChange({ target: { name: 'origin', value: data?.value || null } });
              }}
            />
          </Box>
        </Grid>

        {recordType === 'terrestrial' &&
          <Grid item md={6} xs={6}>
            <TypographicInputLabel htmlFor={`${index}-abundance`}>Abundancia</TypographicInputLabel>
            <TextField value={record.abundance ?? ''} name="abundance" id={`${index}-abundance`}
              variant="outlined" size="small" onBlur={onBlurNumber} fullWidth autoComplete="off"
              onChange={onChangeNumber} placeholder={'No informada'}
            />
          </Grid>
        }

        <Grid item md={12} xs={12}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${index}-record-status`}>Estado de la especie</TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Autocomplete
              multiple
              value={record.recordStatus}
              name={'recordStatus'}
              options={speciesStatusOptions}
              getOptionLabel={getSpeciesStatuslabels}
              getOptionSelected={getOptionSelected}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${index}-record-status`}
                  variant="outlined"
                  placeholder={record.recordStatus.length ? '' : 'Cr'}
                />
              )}
              onChange={(event, data) => {
                onChange({ target: { name: 'recordStatus', value: data.map(opt => typeof opt === 'string' ? opt : opt.value) } });
              }}
            />
          </Box>
        </Grid>

      </Grid>
    </AccordionDetails>
  </Accordion>;
});

FaunaRecordForm.propTypes = {
  record: PropTypes.object,
  index: PropTypes.number,
  onUpdate: PropTypes.func,
  originOptions: PropTypes.object.isRequired,
  recordType: PropTypes.string,
  deleteRecord: PropTypes.func,
  containsScientificName: PropTypes.func,
};

FaunaRecordForm.displayName = 'FaunaRecordForm';


export { FaunaRecordForm };