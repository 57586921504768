const baseline = {
  project: {
    key: 'project',
    path: '/app/load-project',
    name: 'Proyecto',
  },
  flora: {
    key: 'flora',
    path: '/app/flora',
    name: 'Flora',
  },
  archeology: {
    key: 'archeology',
    path: '/app/archeology',
    name: 'Arqueología',
  },
  areasOfInfluence: {
    key: 'areas-of-influence',
    path: '/app/areas-of-influence',
    name: 'Áreas de Influencia',
  },
  partsAndWorks: {
    key: 'parts-and-works',
    path: '/app/parts-and-works',
    name: 'Partes y obras',
  },
  soil: {
    key: 'soil',
    path: '/app/soil',
    name: 'Suelo',
  },
  noise: {
    key: 'noise',
    path: '/app/noise',
    name: 'Ruido y vibraciones',
  },
  landscape: {
    key: 'landscape',
    path: '/app/landscape',
    name: 'Paisajes',
  },
  fauna: {
    key: 'fauna',
    path: '/app/fauna',
    name: 'Fauna',
  },
};


export { baseline };