import { del, get, put } from 'src/services/api';


const saveForm = async data => await put('/fauna', data);
const getData = async seaProjectId => (await get(`/fauna/data/${seaProjectId}`)).data;
const deleteRecord = async (seaProjectId, recordId) => (await del(`/fauna/${seaProjectId}/record/${recordId}`));
const deleteCampaign = async (seaProjectId, campaignId) => (await del(`/fauna/${seaProjectId}/campaign/${campaignId}`));


export {
  saveForm,
  getData,
  deleteRecord,
  deleteCampaign,
};