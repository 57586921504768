import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { ProjectYearSelection } from 'src/scenes/LoadProject/components';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

import Loading from 'src/components/Loading';
import faunaSchema, { arraySchemaIds } from 'src/scenes/Fauna/faunaValidationSchema';
import { Map, Page, Section, Project, LeaveConfirm, AlertsList, DialogWrapper, ObservationsSection,
  SelectComponentSection, RemoveGeomsDialog, ConfirmationDialog } from 'src/components';
import { CampaignsForm, SamplingStationForm } from 'src/scenes/Fauna/components';
import { projectApi, faunaApi, optionsApi } from 'src/services';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { filterGeomTypeFromGeoJson, isInvalidGeometry, geometryToFeature, makeErrors,
  separateCollectionsFromFeatureCollection } from 'src/utils';
import { useMustLeave, useMustReload, useSetHighlightedFeat } from 'src/hooks';


const typeKey = 'fauna';

const DialogContents = ({ type, actions }) => {
  switch (type) {
    case 'remove-geoms': {
      return <RemoveGeomsDialog
        confirmFn={actions.deleteGeoJson}
        closeDialog={actions.closeDialog}
        type="soil"
      />;
    }
    case 'upload-kml-fauna-section': {
      return <SelectComponentSection
        confirmFn={actions.confirmDialog}
        closeDialog={actions.closeDialog}
        sections={[
          { label: 'Estación terrestre', value: 'terrestrial' },
          { label: 'Estación acuática', value: 'aquatic' },
        ]}
      />;
    }
    case 'delete-record': {
      return <ConfirmationDialog
        onConfirm={actions.confirmDialog}
        closeDialog={actions.closeDialog}
        yesMessage='Borrar datos'
        title='¿Realmente deseas eliminar el registro y sus datos?'
        dangerous
      />;
    }
    case 'delete-campaign': {
      return <ConfirmationDialog
        onConfirm={actions.confirmDialog}
        closeDialog={actions.closeDialog}
        yesMessage='Borrar datos'
        title='¿Realmente deseas eliminar la campaña y sus datos?'
        message='Si habían estaciones vinculadas a la campaña recuerda guardar el progreso para reflejar su nuevo estado.
          De no ser así estas estaciones pasaran a estar desfinalizadas y sin campaña vinculada.'
        dangerous
      />;
    }
    default:
      break;
  }
};

DialogContents.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  actions: PropTypes.object,
};

const accordionTransitionPropObject = { unmountOnExit: true };

const useStyles = makeStyles(theme => ({
  comments: {
    width: '100%',
  },
  hr: {
    marginLeft: 0,
    width: '25%',
  },
  success: {
    color: 'green',
  },
  accordionSummary: {
    backgroundColor: '#eee',
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
}));

const FaunaForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { seaProjectId } = match.params;

  const history = useHistory();
  const pushProjectToHistory = seaProject => history.push({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const replaceProjectToHistory = seaProject => history.replace({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const { state: locationState } = useLocation();
  const [ seaProject, setSeaProject ] = useState(locationState?.seaProject);
  const [ kmlAlerts, setKmlAlerts ] = useState([]);
  const [ dialog, setDialog ] = useState({ isOpen: false, type: null, data: null, actions: null });
  const [ sendingData, setSendingData ] = useState(false);
  const [ formOptions, setFormOptions ] = useState(null);
  const [ campaignExpanded, setCampaignExpanded ] = useState(false);

  const [ form, setForm ] = useState({
    geoJson: null,
    badData: false,
    campaigns: [],
    terrestrial: [],
    aquatic: [],
    comments: '',
  });

  const [ kmlFileNamesBySection, setKmlFileNamesBySection ] = useState({
    terrestrial: [],
    aquatic: [],
  });

  const makeEmptyElement = {
    campaigns: () => ({
      tempId: form.campaigns.length === 0 ? 0 : form.campaigns[form.campaigns.length - 1].tempId + 1,
      name: '',
      dataOnWeb: false,
      finished: false,
      absoluteAbundance: null,
      startDate: null,
      preciseDate: true,
      durationDays: null,
      season: null,
    }),
    terrestrial: ({ featId, name = '', dataOnWeb = false }) => ({
      name,
      featId,
      dataOnWeb,
      finished: false,
      campaignIds: [],
      records: [],
      stationType: 'terrestrial',
    }),
    aquatic: ({ featId, name = '', dataOnWeb = false }) => ({
      name,
      featId,
      dataOnWeb,
      finished: false,
      campaignIds: [],
      records: [],
      absoluteFrequency: null,
      stationType: 'aquatic',
    }),
  };

  const createSpeciesRegister = useCallback(() => ({
    abundance: null,
    scientificName: null,
    commonName: null,
    origin: null,
    recordStatus: [],
  }), []);

  const layersRef = useRef({});

  const featurePopUp = feature => {
    const fProps = feature.properties;
    return `<div>${feature.properties.sectionLabel} #${fProps.elementNumber}</div>`;
  };

  const onEachFeature = (feature, layer) => {
    layer.bindPopup(featurePopUp(feature));
    layersRef.current[feature.properties.id] = layer;
  };

  const [ loadingProject, setLoadingProject ] = useState(!seaProject);
  const [ loadedFormDataAndOptions, setLoadedFormDataAndOptions ] = useState(false);
  const [ projectYear, setProjectYear ] = useState(seaProject?.year ?? null);
  const [ randomProjectNotFound, setRandomProjectNotFound ] = useState(false);
  const [ errors, setErrors ] = useState({});
  const dbDataRef = useRef(null);

  const { mustLeave, leavePage } = useMustLeave({ history });
  const { mustReload, reloadPage } = useMustReload();

  const lastSavedCommentsRef = useRef('');
  // cosas para prevenir que maten la página por accidente cuando tienen datos no guardados:
  const checkLeaveConfirmNeeded = () => !mustLeave &&
    form.comments !== lastSavedCommentsRef.current ||
    form.campaigns.some(c => !c.dataOnWeb) || form.terrestrial.some(gss => !gss.dataOnWeb) ||
    form.aquatic.some(ass => !ass.dataOnWeb);

  const closeDialog = useCallback(() => setDialog({ isOpen: false, type: null, data: null, actions: null }), []);

  const onSelectedSection = useCallback((filteredGeoJson, kmlFileName) => selected => {
    const { value: sectionName, label: sectionLabel } = selected;
    if (kmlFileNamesBySection[sectionName].includes(kmlFileName)) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `Ya ha sido cargado un archivo con el nombre ${kmlFileName} en esa sección.` },
      ]);
      return;
    }

    const pointElements = [];

    for (let i = 0; i < filteredGeoJson.features.length; i++) {
      const feature = filteredGeoJson.features[i];
      const { properties } = feature;

      let name;

      Object.keys(properties).forEach(key => {
        const normalizedKey = key.toLowerCase().trim();
        if (normalizedKey === 'nombre' || normalizedKey === 'name') {
          name = properties[key]?.trim();
        }
      });

      const nameStr = name ? `Nombre: ${name}` : '';
      if (isInvalidGeometry(feature, { allowMulti: true })) {
        setKmlAlerts([ { type: 'error', message: `Se encontró una geometría con coordenadas inválidas ${nameStr}` } ]);
        return;
      }
      const featId = i + (form.geoJson?.features?.length ?? 0);
      const featProps = { name, id: featId, sectionLabel };

      pointElements.push(makeEmptyElement[sectionName]({ featId, name }));
      featProps.elementNumber = pointElements.length;
      featProps.sectionLabel = sectionLabel;

      filteredGeoJson.features[i] = { ...feature, properties: featProps };
    }
    setForm(pf => ({
      ...pf,
      geoJson: {
        type: 'FeatureCollection',
        features: [ ...(pf.geoJson?.features ?? []), ...filteredGeoJson.features ],
      },
      ...({ [sectionName]: [ ...(pf[sectionName] ?? []), ...pointElements ] }),
    }));

    setKmlFileNamesBySection(pf => ({
      ...pf,
      [sectionName]: [ ...pf[sectionName], kmlFileName ],
    }));

    return;
  // eslint-disable-next-line
  }, [form, kmlFileNamesBySection]);

  const openSelectFaunaSection = useCallback((geoJson, kmlFileName) => {
    setDialog({
      isOpen: true, type: 'upload-kml-fauna-section',
      actions: { closeDialog, confirmDialog: onSelectedSection(geoJson, kmlFileName) },
    });
  }, [ closeDialog, onSelectedSection ]);

  useEffect(() => {
    if (seaProjectId) {
      const fetchFormDataAndOptions = async () => {
        try {
          const [ prevData, originOptions ] = await Promise.all([
            faunaApi.getData(seaProjectId),
            optionsApi.getFaunaOriginOptions(),
          ]);
          if (prevData) {
            const features = [ ...Array(prevData.terrestrial.length + prevData.aquatic.length) ];
            const tempData = {
              campaigns: [],
              terrestrial: [],
              aquatic: [],
            };
            arraySchemaIds.forEach(formName => {
              for (let ind = 0; ind < prevData[formName].length; ind++) {
                const { geometry, ...restData } = prevData[formName][ind];
                const { name, featId, sectionLabel } = restData;
                tempData[formName].push({ ...restData, dataOnWeb: true });
                if (geometry) {
                  features[featId] = geometryToFeature({
                    geometry, id: featId, properties: { name, featId, elementNumber: ind + 1, sectionLabel },
                  });
                }
              }
            });
            setForm({
              geoJson: {
                type: 'FeatureCollection',
                features,
              },
              ...tempData,
              comments: prevData.comments,
              badData: prevData.badData,
            });
            lastSavedCommentsRef.current = prevData.comments;
          }
          dbDataRef.current = prevData;
          setFormOptions({
            originOptions: {
              options: originOptions,
              labels: originOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}),
            },
          });
          setLoadedFormDataAndOptions(true);
        } catch (e) {
          console.log(e);
          toast.error(
            e.serverMessage ?? e.serviceMessage ?? 'Hubo un error al cargas los datos del formulario, por favor intenta más tarde',
          );
        }
      };
      fetchFormDataAndOptions();
    } else {
      getPrioritizedProject();
    }
  // eslint-disable-next-line
  }, [ seaProjectId ]);

  // TODO: estado de error y mensaje de error para esto
  useEffect(() => {
    if (seaProjectId && !seaProject) {
      const fetchData = async () => {
        setLoadingProject(true);
        const { seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setSeaProject(seaProject);
        setLoadingProject(false);
      };
      fetchData();
    }
  }, [ seaProjectId, seaProject ]);

  const getPrioritizedProject = async () => {
    try {
      const result = (await projectApi.getPriorityProject({ type: typeKey }))?.data;
      if (result) {
        const { seaProject } = result;
        setSeaProject(result.seaProject);
        if (!seaProject) {
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
          replaceProjectToHistory(seaProject);
        }
      } else {
        setLoadingProject(false);
      }
    } catch (err) {
      setLoadingProject(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
    }
  };

  const getRandomizedProject = async year => {
    setLoadingProject(true);
    setRandomProjectNotFound(false);
    try {
      const result = (await projectApi.getRandomProject({ year, type: typeKey }))?.data;
      const { seaProject } = result || {};
      if (!seaProject) {
        setRandomProjectNotFound(true);
        setLoadingProject(false);
      } else {
        setSeaProject(seaProject);
        setLoadingProject(false);
        pushProjectToHistory(seaProject);
      }
    } catch (err) {
      toast.error(err.serverMessage ?? err.serviceMessage ?? 'Hubo un error al pedir el proyecto. Por favor intenta más tarde');
      console.error(err);
    }
  };

  const onChangeProjectYear = ({ projectYear }) => {
    setProjectYear(projectYear);
    getRandomizedProject(projectYear);
  };

  const handleGeoJson = ({ geoJson, kmlFileName }) => {

    if (!geoJson.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece contener geometrías o no se pudieron analizar sus geometrías` },
      ]);
    }
    const validTypes = [ 'Point', 'MultiPoint' ];
    const { geoJson: filteredGeoJson, changed, changedObj } = filterGeomTypeFromGeoJson({ geoJson, validTypes });
    if (!filteredGeoJson?.features?.length) {
      setKmlAlerts(pa => [
        ...pa.filter(al => al.type === 'warning'),
        { type: 'error', message: `El KML cargado no parece tener puntos` },
      ]);
      return;
    } else if (changed) {
      // Si se filtró algo del ḰML, advertir y continuar.
      setKmlAlerts(ps => {
        let typeFilterWarningActive = false;
        let nullFilterWarningActive = false;
        const finalList = ps.filter(w => {
          typeFilterWarningActive |= w.key === 'type-filter';
          nullFilterWarningActive |= w.key === 'null-filter';
          return w.type === 'warning';
        });

        const newWarnings = [];
        for (const changeKey in changedObj) {
          if (changeKey === 'null' && !nullFilterWarningActive) {
            newWarnings.push({
              type: 'warning',
              message: `Se encontraron y filtraron geometrías vacías. Considera revisar el KML si esto no te parece correcto`,
              key: 'null-filter',
            });
            nullFilterWarningActive = true;
          } else if (changeKey !== 'null' && !typeFilterWarningActive) {
            newWarnings.push({
              type: 'warning', message: `Se han filtrado geometrías que no son puntos`, key: 'type-filter',
            });
            typeFilterWarningActive = true;
          }
        }
        return [ ...finalList, ...newWarnings ];
      });
    } else {
      setKmlAlerts(pa => {
        // Limpiamos errores de kmls pasados que no puedieron subirse, pero dejamos las warnings (que son de kmls que sí se subieron)
        const warnings = pa.filter(alert => alert.type === 'warning');
        return warnings;
      });
    }

    const processedGeoJson = separateCollectionsFromFeatureCollection({ featureCollection: filteredGeoJson });
    openSelectFaunaSection(processedGeoJson, kmlFileName);
  };

  const onSubmitGenerator = (stayHere = false) => async () => {
    if (sendingData || mustLeave) {
      return;
    }
    try {
      setErrors({});
      form.seaId = seaProjectId;
      setSendingData(true);
      await faunaSchema.validate(form, { abortEarly: false });
      toast.info('Guardando la información');
      const { message } = await faunaApi.saveForm(form);
      setSendingData(false);
      toast.dismiss();
      toast.success(message);
      if (!stayHere) {
        leavePage();
      } else {
        reloadPage();
      }
    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      if (e.name === 'ValidationError') {
        toast.error(<div>Hay problemas con el formulario.<br/>Por favor revisar</div>,
          { autoClose: 10000, allowHtml: true },
        );
        const formErrors = makeErrors(e, arraySchemaIds);
        console.error(`Problem submit form: ${e}`);
        console.error({ formErrors });
        setErrors(formErrors);
      } else {
        console.error(e);
        toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
      }
    }
  };

  /*
  const goToStation = ({ stationId = 1 }) => {
    history.push(`${stationId}/form`);
  };*/

  const onSubmit = onSubmitGenerator(false);
  const onSubmitReloadPage = onSubmitGenerator(true);

  const { setHighlightedFeat } = useSetHighlightedFeat({ layersRef });

  const addCampaign = () => {
    setForm(pf => ({
      ...pf,
      ...({ campaigns: [ ...(pf['campaigns'] ?? []), makeEmptyElement['campaigns']() ] }),
    }));
    setCampaignExpanded(true);
  };

  const deleteGeoJson = useCallback(() => {
    setForm(pf => ({
      ...pf, geoJson: null, terrestrial: [], aquatic: [],
      ...(dbDataRef.current && { deletePreviousData: true }),
    }));
    setKmlAlerts([]);
    setHighlightedFeat({ featId: null });
    setKmlFileNamesBySection({
      terrestrial: [],
      aquatic: [],
    });
  // eslint-disable-next-line
  }, [ setHighlightedFeat ]);

  const openDeleteGeoJsonDialog = useCallback(() =>
    setDialog({ isOpen: true, type: 'remove-geoms', actions: { closeDialog, deleteGeoJson } })
  , [ closeDialog, deleteGeoJson ]);

  const openDeleteRecord = useCallback((recordIndex, record, stationType, stationIndex) => {
    setDialog({
      isOpen: true, type: 'delete-record',
      actions: { closeDialog, confirmDialog: deleteRecord(recordIndex, record, stationType, stationIndex) } });
  // eslint-disable-next-line
  }, []);

  const deleteRecord = useCallback((recordIndex, record, stationType, stationIndex) => async () => {
    try {
      if (record.id) {
        await faunaApi.deleteRecord(seaProjectId, record.id);
      }
      setForm(pf => ({
        ...pf,
        [stationType]: pf[stationType].map((ss, currInd) =>
          stationIndex === currInd ? ({
            ...ss,
            records: pf[stationType][stationIndex].records.filter((record, i) => i !== recordIndex),
          }) : ss),
      }));
    } catch (e) {
      toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
    }
  }, [ seaProjectId ]);

  const deleteCampaign = useCallback((campaignIndex, campaign) => async () => {
    try {
      if (campaign.dbId) {
        await faunaApi.deleteCampaign(seaProjectId, campaign.dbId);
      }
      setForm(pf => ({
        ...pf,
        campaigns: pf.campaigns.filter((campaign, i) => i !== campaignIndex),
      }));
    } catch (e) {
      toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
    }
  }, [ seaProjectId ]);

  const openDeleteCampaign = useCallback((campaignIndex, campaign) => {
    setDialog({
      isOpen: true, type: 'delete-campaign',
      actions: { closeDialog, confirmDialog: deleteCampaign(campaignIndex, campaign) },
    });
  }, [ closeDialog, deleteCampaign ]);

  const formStatus = useMemo(() => {
    if (!loadedFormDataAndOptions) {
      return null;
    }
    const dbData = dbDataRef.current;
    if (dbData) {
      if (!form.geoJson && !form.badData) {
        return { message: 'Pendiente', status: 'pending', updatedAt: dbData.updatedAt };
      } else if (form.geoJson && (form.campaigns.some(c => !c.finished) || form.terrestrial.some(ss => !ss.finished)
       || form.aquatic.some(ss => !ss.finished)
      )) {
        return {
          message: 'En progreso (faltan datos de formularios por completar)',
          status: 'inProgress',
          updatedAt: dbData.updatedAt,
        };
      } else {
        return { message: 'Enviado', status: 'success', updatedAt: dbData.updatedAt };
      }
    } else {
      return { message: 'Pendiente', status: 'pending' };
    }
  // eslint-disable-next-line -- Todas las dependencias están bien en el punto en que este valor cambia
  }, [ loadedFormDataAndOptions ])

  const stopEventPropagation = event => event.stopPropagation();

  const clickCampaignAccordion = () => {
    setCampaignExpanded(!campaignExpanded);
  };

  return loadingProject ? <Loading/> : <>
    {!seaProject && <>
      <ProjectYearSelection formType={typeKey} projectYear={projectYear} updateState={onChangeProjectYear} />
      { randomProjectNotFound && <Typography variant="body1">No se encontró proyecto para el año seleccionado</Typography> }
    </>}
    { seaProject && (!loadedFormDataAndOptions ? <Loading/> : <>
      <Section title="Proyecto">
        { !mustLeave && !mustReload && <LeaveConfirm checkConfirmNeeded={checkLeaveConfirmNeeded}/> }
        <Box>
          <Project project={seaProject} formType={typeKey} formStatus={formStatus}></Project>
        </Box>
      </Section>
      <Page title={`Fauna - ${seaProject.nombre}`}>
        <Box px={4}>
          <Map nameId={'faunaMap'} geoJson={form.geoJson} importKml={true} deleteGeoJson={openDeleteGeoJsonDialog}
            mapHeight={'24rem'} allowMulti handleGeoJson={handleGeoJson} onEachFeature={onEachFeature}
          />
        </Box>
        <Box mx={4} my={2}>
          { Boolean(kmlAlerts.length) &&
            <AlertsList alerts={kmlAlerts} />
          }
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={form.badData} onChange={event => setForm(pf => ({ ...pf, badData: event.target.checked }))}
              />
            }
            labelPlacement="start"
            label="¿Datos erróneos o no disponibles?"
          />
        </Box>
        <Box display='flex' mt={ 4 } mb={ 1 } justifyContent='space-between'>
          <Typography variant="h4" gutterBottom>
            Fauna
          </Typography>
        </Box>


        <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}
            expanded={form.campaigns.length > 0 && campaignExpanded} onChange={clickCampaignAccordion}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>Campañas <small>({form.campaigns.length})</small></Typography>
              <Box mx={1.5} onClick={stopEventPropagation}>
                <Button variant="contained" color="secondary" onClick={ addCampaign }>
                  Añadir Campaña
                </Button>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.campaigns.map((c, index) =>
                  <CampaignsForm campaign={c} index={index} setForm={setForm} key={`c-${index}`}
                    terrestrial={form.terrestrial} aquatic={form.aquatic} deleteCampaign={openDeleteCampaign}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        { Boolean(form.terrestrial.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" gutterBottom>
                Estaciones de muestreo para fauna terrestre <small>({form.terrestrial.length})</small>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.terrestrial.map((gss, index) =>
                  <SamplingStationForm index={index} samplingStation={gss} key={`gss-${index}`}
                    setForm={setForm} campaigns={form.campaigns} originOptions={formOptions.originOptions}
                    createSpeciesRegister={createSpeciesRegister} setHighlightedFeat={setHighlightedFeat}
                    openDeleteRecord={openDeleteRecord} errors={errors.terrestrial?.[index]}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        { Boolean(form.aquatic.length) && <Box mt={2}>
          <Accordion defaultExpanded={false} TransitionProps={accordionTransitionPropObject}>
            <AccordionSummary className={ classes.accordionSummary } expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5"gutterBottom>
                Estaciones de muestreo para fauna acuatica <small>({form.aquatic.length})</small>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width='100%'>
                { form.aquatic.map((ass, index) =>
                  <SamplingStationForm index={index} samplingStation={ass} key={`ass-${index}`}
                    setForm={setForm} campaigns={form.campaigns} originOptions={formOptions.originOptions}
                    createSpeciesRegister={createSpeciesRegister} setHighlightedFeat={setHighlightedFeat}
                    openDeleteRecord={openDeleteRecord} errors={errors.aquatic?.[index]}
                  />,
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>}

        <ObservationsSection onChange={e => setForm(pf => ({ ...pf, comments: e.target.value }))}
          value={form.comments} className={classes.comments}
        />
        { errors.geoJson &&
          <Box>
            <Alert severity="error">{errors.geoJson.errorMessage}</Alert>
          </Box>
        }
        { (errors.terrestrial || errors.terrestrial) &&
          <Box>
            <Alert severity="error">Error en los datos de las estaciones</Alert>
          </Box>
        }
        <Box display="flex" flex={2}>
          <Box mx={1.5}>
            <Button className={classes.submitButton} type="button" variant="contained" color="secondary"
              disabled={sendingData || mustLeave} onClick={onSubmitReloadPage} >
              Guardar progreso
            </Button>
          </Box>
          <Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary"
              disabled={sendingData || mustLeave } onClick={onSubmit} >
              Enviar
            </Button>
          </Box>
        </Box>
        <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
          { dialog.isOpen && <DialogContents type={dialog.type} actions={dialog.actions} data={dialog.data} /> }
        </DialogWrapper>
      </Page>
    </>)}
  </>;
};


export { FaunaForm };
